<template>
  <mdb-container>
    <mdb-navbar class="flexible-navbar white" position="top" double scrolling>
      <mdb-navbar-brand @click.native="toggleSideNav" class="button-collapse">
        <mdb-icon icon="bars"/>
      </mdb-navbar-brand>
      <mdb-navbar-nav left>
        <mdb-breadcrumb>{{computedPage.activePage}}</mdb-breadcrumb>
      </mdb-navbar-nav>
      <mdb-navbar-toggler/>
      <mdb-navbar-nav class="right-nav" right>
        <mdb-nav-item icon="user" far anchorClass="secondary-menu-item" @click.native="openEditModal()">
          Account
        </mdb-nav-item>
        <mdb-nav-item icon="sign-out-alt" anchorClass="secondary-menu-item" @click.native="openSignOutModal()">
          Logout
        </mdb-nav-item>
      </mdb-navbar-nav>
    </mdb-navbar>

    <!-- Modal: Edit Account -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showEditModal"
      @close="showEditModal = false"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Account bearbeiten</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveEditModal" @keydown.enter.prevent>
          <mdb-row>
            <mdb-col md="6" class="mb-3" style="border-right: 1px solid #aaa;">
              <legend><mdb-icon icon="user" class="blue-text" /> Ansprechpartner</legend>
              <input type="hidden" v-model="model.id">
              <mdb-input
                label="Firma"
                type="text"
                class="mb-3 mt-3"
                v-model="model.company"
                required
              />
              <mdb-select
                label="Anrede"
                class="mt-3 mb-0"
                v-model="model.title"
                ref="title"
                validation
              />
              <mdb-input
                label="Titel"
                type="text"
                class="mt-0 mb-3"
                v-model="model.title_honorific"
              />
              <mdb-input
                label="Vorname"
                type="text"
                class="mb-3 mt-3"
                v-model="model.first_name"
                required
              />
              <mdb-input
                label="Nachname"
                type="text"
                class="mb-3 mt-3"
                v-model="model.last_name"
                required
              />
              <legend><mdb-icon icon="phone" class="blue-text mt-4" /> Kontakt</legend>
              <mdb-input
                label="Telefon"
                type="text"
                class="mb-3 mt-3"
                v-model="model.phone"
              />
              <mdb-input
                label="Mobil"
                type="text"
                class="mb-3 mt-3"
                v-model="model.mobile"
              />
              <mdb-input
                label="Fax"
                type="text"
                class="mb-3 mt-3"
                v-model="model.fax"
              />
              <mdb-input
                label="E-Mail"
                type="text"
                class="mb-3 mt-3"
                v-model="model.email"
                required
              />
            </mdb-col>
            <mdb-col md="6" class="mb-3">
              <legend><mdb-icon icon="map-marked-alt" class="blue-text" /> Anschrift</legend>
              <mdb-input
                label="Straße"
                type="text"
                class="mb-3 mt-3"
                v-model="model.street"
              />
              <mdb-input
                label="PLZ"
                type="text"
                class="mb-3 mt-3"
                v-model="model.zip"
              />
              <mdb-input
                label="Stadt"
                type="text"
                class="mb-3 mt-3"
                v-model="model.city"
              />
              <mdb-input
                label="Region"
                type="text"
                class="mb-3 mt-3"
                v-model="model.region"
              />
              <mdb-input
                label="Land"
                type="text"
                class="mb-3 mt-3"
                v-model="model.country"
              />
              <legend><mdb-icon icon="key" class="blue-text mt-4" /> Passwort</legend>
              <p class="small">Dieser Bereich muss nur ausgefüllt werden, wenn Sie ihr Passwort ändern wollen.</p>
              <p class="small">Passwörter müssen aus mindestens 12 Zeichen bestehen.</p>
              <mdb-input
                id="current-password"
                label="Aktuelles Passwort"
                type="password"
                class="mb-3 mt-3"
                v-model="model.current_password"
              />
              <mdb-input
                id="new-password"
                label="Neues Passwort"
                type="password"
                minlength="12"
                class="mb-3 mt-3"
                v-model="model.new_password"
              />
              <mdb-input
                id="confirmed-password"
                label="Neues Passwort (Wiederholung)"
                type="password"
                minlength="12"
                class="mb-3 mt-3"
                v-model="model.confirmed_password"
              />
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="blue" @click.native="showEditModal = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Speichern
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Edit Account -->
    <!-- Modal: Sign Out -->
    <mdb-modal :show="showSignOutModal" @close="showSignOutModal = false">
      <mdb-modal-header color="red">
        <mdb-modal-title>Logout</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="sign-out-alt" size="4x" class="red-text ml-3"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie sich ausloggen?</strong>
            </p>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showSignOutModal = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="signOut">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Logout
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Sign Out -->
  </mdb-container>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapActions } from 'vuex'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavItem,
  mdbNavbarNav,
  mdbNavbarToggler,
  mdbBreadcrumb,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbSelect
} from 'mdbvue'

export default {
  name: 'Navbar',
  props: {
    page: {
      type: String
    }
  },
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavItem,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbBreadcrumb,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbSelect
  },
  data () {
    return {
      loading: false,
      activePage: this.page,
      toggle: false,
      showEditModal: false,
      showSignOutModal: false,
      username: localStorage.getItem('username'),
      password: localStorage.getItem('password'),
      validPassword: false,
      model: {
        company: '',
        title: [
          { text: 'Herr', value: 'Herr', selected: true },
          { text: 'Frau', value: 'Frau', selected: false }
        ],
        title_honorific: '',
        first_name: '',
        last_name: '',
        street: '',
        zip: '',
        city: '',
        region: '',
        country: '',
        phone: '',
        mobile: '',
        fax: '',
        email: '',
        current_password: '',
        new_password: '',
        confirmed_password: ''
      }
    }
  },
  computed: {
    computedPage () {
      return {
        activePage: this.page
      }
    }
  },
  mounted() {
    if (localStorage.username) {
      this.username = localStorage.username
    }
    if (localStorage.password) {
      this.password = localStorage.password
    }
  },
  methods: {
    ...mapActions(['toggleSideNav']),
    openEditModal() {
      axios.get('get_user.php').then(response => {
        this.model = response.data
        this.showEditModal = true
      })
    },
    saveEditModal(event) {
      this.wasValidated = true
      event.target.classList.add('was-validated')

      if (this.model.current_password && this.model.current_password === this.password && this.model.new_password && this.model.new_password.length >= 12 && this.model.new_password === this.model.confirmed_password) {
        document.getElementById('current-password').setCustomValidity('')
        document.getElementById('new-password').setCustomValidity('')
        document.getElementById('confirmed-password').setCustomValidity('')
        this.validPassword = true
      } else if (!this.model.current_password && !this.model.new_password && !this.model.confirmed_password) {
        document.getElementById('current-password').setCustomValidity('')
        document.getElementById('new-password').setCustomValidity('')
        document.getElementById('confirmed-password').setCustomValidity('')
        this.validPassword = true
      } else {
        document.getElementById('current-password').setCustomValidity('invalid')
        document.getElementById('new-password').setCustomValidity('invalid')
        document.getElementById('confirmed-password').setCustomValidity('invalid')
        this.validPassword = false
      }
      if (this.model.company !== '' && this.model.title !== '' && this.model.first_name !== '' && this.model.last_name !== '' && this.model.email !== '' && this.validPassword === true) {
        this.loading = true
        var url = 'edit_user.php'
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          this.loading = false
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            this.showEditModal = false
          }
        }).catch(error => {
          this.loading = false
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    openSignOutModal() {
      this.showSignOutModal = true
    },
    signOut() {
      axios.get('logout.php').then(response => {
        if (response.data.override === true) {
          localStorage.setItem('username', 'newsweek')
          window.location.href = Vue.prototype.$serverUrl
        } else {
          localStorage.removeItem('loggedIn');
          localStorage.removeItem('usergroup');
          localStorage.removeItem('username');
          localStorage.removeItem('password');
          localStorage.removeItem('eventCategory');
          localStorage.removeItem('eventYear');
          localStorage.removeItem('event');
          window.location.href = Vue.prototype.$serverUrl
        }
        this.showSignOutModal = false
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.navbar .nav-item {
  line-height: 24px;
  font-size: 16px;
}
.navbar .dropdown-toggle {
  padding: 0 10px;
  font-size: 16px;
}

.navbar ul {
  padding: 5px 0;
}

.navbar .btn {
  font-weight: 300;
}

.navbar-collapse {
  order: 0 !important;
}

.navbar .notifications-nav .dropdown-menu {
  width: 23rem; }

.navbar-expand-lg .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.btn-transparent {
  background-color: transparent !important;
  color: #000;
  text-transform: none;
  box-shadow: none !important;
}

.btn-transparent:hover, .btn-transparent:active {
  box-shadow: none !important;
}

.breadcrumb {
  font-size: 20px !important;
  line-height: 22px !important;
  text-transform: uppercase;
}

</style>
