// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '../src/assets/css/base-material.css'
import '../src/assets/css/buttons-material.css'
import '../src/assets/css/calendars-material.css'
import '../src/assets/css/dropdowns-material.css'
import '../src/assets/css/grids-material.css'
import '../src/assets/css/inputs-material.css'
import '../src/assets/css/navigations-material.css'
import '../src/assets/css/popups-material.css'
import '../src/assets/css/splitbuttons-material.css'
import '../src/assets/css/promowolsch.css'
import axios from 'axios'

window.axios = axios
if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
  Vue.prototype.$serverUrl = 'https://dev9.sempre-diritto.de/'
  axios.defaults.withCredentials = false
} else {
  Vue.prototype.$serverUrl = 'https://dev9.sempre-diritto.de/'
  axios.defaults.withCredentials = true
}
axios.defaults.baseURL = Vue.prototype.$serverUrl + 'api/'

Vue.config.productionTip = false

export const bus = new Vue()

new Vue({
  render: h => h(App),
  router,
  store,
  components: { App }
}).$mount('#app')
