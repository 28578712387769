<template>
  <mdb-footer class="fixed-bottom">
    <p class="footer-copyright mb-0 py-3 text-center">
      Promowolsch Produktdatenbank v0.5
    </p>
  </mdb-footer>
</template>

<script>
import {
  mdbFooter
} from 'mdbvue'

export default {
  name: 'Footer',
  components: {
    mdbFooter
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

footer {
  background-color: #cccccc !important;
  margin-top: 0px !important;
}

</style>
