<template>
  <div id="app" class="fixed-sn">

    <!-- Section: Login -->
    <section v-if="loggedIn != true" class="text-center mt-5">
      <img src="logo-2.png" width="300" class="mb-5">
      <mdb-row>
        <mdb-col></mdb-col>
        <mdb-col col="10" sm="10" md="8" lg="6" xl="4">
          <mdb-card>
            <mdb-view gradient="blue">
              <h2 class="card-header-title white-text mt-4 mb-4">Promowolsch Produktdatenbank</h2>
            </mdb-view>
            <mdb-card-body class="mx-4 mt-4">
              <mdb-input v-model="username" label="Benutzername" type="text"/>
              <mdb-input v-model="password" label="Passwort" type="password" containerClass="mb-0"/>
              <span class="text-danger">{{ loginError }}</span>
              <mdb-btn block size="lg" color="info" class="mt-4 ml-0" @click.native="login">Login</mdb-btn>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col></mdb-col>
      </mdb-row>
    </section>
    <!-- /Section: Login -->

    <!--SideNav--> 
    <side-nav v-if="loggedIn === true" class="white-skin"/>
    <!--/SideNav-->

    <div v-if="loggedIn === true" class="flexible-content" >
      <!--Navbar-->
      <navbar :page="activePage" />
      <!--/Navbar-->

      <!--MainContent-->
      <main>
        <div>
          <router-view></router-view>
        </div>
      </main>
      <!--/MainContent-->

      <!--Footer-->
      <div class="white-skin">
        <copyrights />
      </div>
      <!--/Footer-->

    </div>

  </div>
</template>

<script>
import {
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbInput,
  mdbView,
  mdbCard,
  mdbCardBody
} from 'mdbvue'
import SideNav from './components/SideNav'
import Navbar from './components/Navbar'
import Copyrights from './components/Footer'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbInput,
    mdbView,
    mdbCard,
    mdbCardBody,
    SideNav,
    Navbar,
    Copyrights
  },
  data () {
    return {
      activePage: 'Dashboard',
      username: localStorage.getItem('username'),
      password: localStorage.getItem('password'),
      loggedIn: localStorage.getItem('loggedIn'),
      eventCategory: localStorage.getItem('eventCategory'),
      eventYear: localStorage.getItem('eventYear'),
      event: localStorage.getItem('event'),
      usergroup: localStorage.getItem('usergroup'),
      loginError: ''
    }
  },
  mounted () {
    this.activePage = this.$route.name
    if (localStorage.username) {
      this.username = localStorage.username
    }
    if (localStorage.password) {
      this.password = localStorage.password
    }
    if (localStorage.loggedIn) {
      this.loggedIn = localStorage.loggedIn
    } else {
      this.loggedIn = false
    }
    if (localStorage.eventCategory) {
      this.eventCategory = localStorage.eventCategory
    }
    if (localStorage.eventYear) {
      this.eventYear = localStorage.eventYear
    }
    if (localStorage.event) {
      this.event = localStorage.event
    }
    if (localStorage.usergroup) {
      this.usergroup = localStorage.usergroup
    }
  },
  updated () {
    this.activePage = this.$route.name
  },
  watch: {
    username (newUsername) {
      localStorage.username = newUsername
    },
    password (newPassword) {
      localStorage.password = newPassword
    },
    loggedIn (newLoggedIn) {
      localStorage.loggedIn = newLoggedIn
    },
    eventCategory (newEventCategory) {
      localStorage.eventCategory = newEventCategory
    },
    eventYear (newEventYear) {
      localStorage.eventYear = newEventYear
    },
    event (newEvent) {
      localStorage.event = newEvent
    },
    usergroup (newUsergroup) {
      localStorage.usergroup = newUsergroup
    }
  },
  methods: {
    login () {
      axios.post('login.php', {
        username: this.username,
        password: this.password,
        event: this.event
      }).then(response => {
        if (response.data.loggedIn === true) {
          this.loginError = response.data.error
          this.loggedIn = true
          this.eventCategory = response.data.event_category
          this.eventYear = response.data.event_year
          this.event = response.data.event
          this.usergroup = response.data.usergroup
        } else {
          this.loginError = response.data.error
        }
      })
    },
    logout () {
      axios.get('logout.php').then(response => {
        if (response.data.loggedIn === false) {
          this.username = ''
          this.password = ''
          this.loggedIn = false
          this.event = ''
          this.associate = ''
          this.resetQuotation(true)
          this.showLogout = false
        } else {
          alert('Es ist ein Fehler aufgetreten!')
        }
      })
    },
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

html {
  font-size: 16px;
  width: 100%;
}

h1, h2, h3, h4, h5, h6, p, button, a, ul, li{
  font-family: 'Roboto', 'Arial', 'Calibri';
}

body {
  font-weight: 300;
  background-color: #eee!important;
}

main {
  padding-top: 61px !important;
  margin: 0px !important;
}

main div.container-fluid:first-child {
  padding: 0;
}

.fixed-sn footer {
  padding-left: 0;
}

.side-nav .logo-sn {
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}

.navbar .btn, .navbar .nav-item, .navbar .nav-link {
  padding: 0;
}

.select-wrapper input.select-dropdown {
  font-size: 14px;
}

.form-control {
  font-size: 14px;
}

.cascading-admin-card {
  margin-top: 20px; }
  .cascading-admin-card .admin-up {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: -20px; }
    .cascading-admin-card .admin-up .fas, .cascading-admin-card .admin-up .far, .cascading-admin-card .admin-up .fab {
      padding: 1.7rem;
      font-size: 2rem;
      color: #fff;
      text-align: left;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
    .cascading-admin-card .admin-up .data {
      float: right;
      margin-top: 2rem;
      text-align: right; }
      .cascading-admin-card .admin-up .data p {
        color: #999999;
        font-size: 12px; }

.classic-admin-card .card-body {
  color: #fff;
  margin-bottom: 0;
  padding: 0.9rem; }
  .classic-admin-card .card-body p {
    font-size: 13px;
    opacity: 0.7;
    margin-bottom: 0; }
  .classic-admin-card .card-body h4 {
    margin-top: 10px; }
  .classic-admin-card .card-body .float-right .fas, .classic-admin-card .card-body .float-right .far, .classic-admin-card .card-body .float-right .fab {
    font-size: 3rem;
    opacity: 0.5; }

.table-ui {
  border: 1px solid #e0e0e9;
}

.fullscreen {
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100000;
  min-height: 100vh;
}

.fullscreen-view {
  height: 100vh;
}

.form-control {
  height: calc(2rem + 1px);
  padding: 0.375rem 0.75rem;
}

.list-group .ml-auto {
  float: right;
  flex: none !important;
}

.datepicker-overlay {
  z-index: 9999 !important;
}

.navbar-brand {
  cursor: pointer
}
</style>
