<template>
  <div class="white-skin mdb-admin-sidenav">
    <mdb-side-nav 
      logo="logo-2.png"
      sideNavClass="sn-bg-1"
      mask="strong"
      :OpenedFromOutside.sync="toggle"
    >
      <li v-show="1===0">
        <mdb-container>
          <mdb-select v-model="eventCategoryOptions" @getValue="getEventCategoryValue" label="Veranstaltungsreihe" />
        </mdb-container>
        <mdb-container>
          <mdb-select v-model="eventYearOptions" @getValue="getEventYearValue" label="Jahr" />
        </mdb-container>
        <mdb-container>
          <mdb-select v-model="eventLocationOptions" @getValue="getEventLocationValue" label="Termin" />
        </mdb-container>
      </li>
      <!--
      <li class="border-bottom border-light"></li>
      -->
      <li>
        <mdb-side-nav-nav>
          <!--
          <mdb-side-nav-item header @click.native="toggle=false" icon="chart-bar" to="/dashboard" class="text-uppercase text-bigger">Dashboard</mdb-side-nav-item>
          <hr>
          <mdb-side-nav-item header @click.native="toggle=false" icon="user-shield" to="/accounts" class="text-uppercase text-bigger" v-show="this.usergroup === 'organizer'">Accounts</mdb-side-nav-item>
          <mdb-side-nav-item header @click.native="toggle=false" icon="user-tag" to="/gruppen" class="text-uppercase text-bigger" v-show="this.usergroup === 'organizer'">Gruppen</mdb-side-nav-item>
          <mdb-side-nav-item header @click.native="toggle=false" icon="calendar-alt" to="/veranstaltungen" class="text-uppercase text-bigger" v-show="this.usergroup === 'organizer'">Veranstaltungen</mdb-side-nav-item>
          <hr v-show="this.usergroup === 'organizer'">
          <mdb-side-nav-item header @click.native="toggle=false" icon="users" to="/teilnehmer" class="text-uppercase text-bigger" v-show="this.usergroup === 'trader'">Teilnehmer</mdb-side-nav-item>
          <mdb-side-nav-item header @click.native="toggle=false" icon="user-tie" to="/mitarbeiter" class="text-uppercase text-bigger" v-show="this.usergroup === 'trader'">Mitarbeiter</mdb-side-nav-item>
          <mdb-side-nav-item header @click.native="toggle=false" icon="user-tag" to="/gruppen" class="text-uppercase text-bigger" v-show="this.usergroup === 'trader'">Gruppen</mdb-side-nav-item>
          <hr v-show="this.usergroup === 'trader'">
          -->
          <mdb-side-nav-item header @click.native="toggle=false" icon="cube" to="/produkte" class="text-uppercase text-bigger" v-show="this.usergroup === 'supplier'">Produkte</mdb-side-nav-item>
          <mdb-side-nav-item header @click.native="toggle=false" icon="stamp" to="/nebenkosten" class="text-uppercase text-bigger" v-show="this.usergroup === 'supplier'">Nebenkosten</mdb-side-nav-item>
          <mdb-side-nav-item header @click.native="toggle=false" icon="wrench" to="/vorkosten" class="text-uppercase text-bigger" v-show="this.usergroup === 'supplier'">Vorkosten</mdb-side-nav-item>
          <hr v-show="this.usergroup === 'supplier'">
          <!--
          <mdb-side-nav-item header @click.native="toggle=false" icon="user-tie" to="/mitarbeiter" class="text-uppercase text-bigger" v-show="this.usergroup === 'supplier'">Mitarbeiter</mdb-side-nav-item>
          <mdb-side-nav-item header @click.native="toggle=false" icon="users" to="/haendler" class="text-uppercase text-bigger" v-show="this.usergroup === 'supplier'">Händler</mdb-side-nav-item>
          <mdb-side-nav-item header @click.native="toggle=false" icon="user-tag" to="/gruppen" class="text-uppercase text-bigger" v-show="this.usergroup === 'supplier'">Händlergruppen</mdb-side-nav-item>
          <hr v-show="this.usergroup === 'supplier'">
          <mdb-side-nav-item header @click.native="toggle=false" icon="file-invoice" to="/anfragen" class="text-uppercase text-bigger" v-show="this.usergroup === 'trader' || this.usergroup === 'supplier'">Anfragen</mdb-side-nav-item>
          <hr v-show="this.usergroup === 'trader' || this.usergroup === 'supplier'">
          <mdb-side-nav-item header @click.native="toggle=false" icon="cog" to="/einstellungen" class="text-uppercase text-bigger" v-show="this.usergroup === 'trader' || this.usergroup === 'supplier' || this.usergroup === 'organizer'">Einstellungen</mdb-side-nav-item>
          -->
        </mdb-side-nav-nav>
      </li>
    </mdb-side-nav>
  </div>
</template>

<script>
import { bus } from '../main'
import {
  mdbContainer,
  mdbSelect,
  mdbSideNav,
  mdbSideNavNav,
  mdbSideNavItem,
  waves
} from "mdbvue"
import { mapGetters, mapActions } from "vuex"
import axios from 'axios'

export default {
  name: "SideNav",
  props: {
    waves: {
      type: Boolean,
      default: true
    },
    open: {
      type: Boolean
    }
  },
  components: {
    mdbContainer,
    mdbSelect,
    mdbSideNav,
    mdbSideNavNav,
    mdbSideNavItem
  },
  data() {
    return {
      active: 0,
      elHeight: 0,
      windowHeight: 0,
      toggle: true,
      width: 0,
      eventCategory: localStorage.getItem('eventCategory'),
      eventYear: localStorage.getItem('eventYear'),
      event: localStorage.getItem('event'),
      eventCategoryOptions: [],
      eventYearOptions: [],
      eventLocationOptions: [],
      usergroup: localStorage.getItem('usergroup')
    }
  },
  computed: mapGetters(["sidenav"]),
  methods: {
    ...mapActions(["toggleSideNav"]),
    afterEnter(el) {
      el.style.maxHeight = "1000px"
    },
    beforeLeave(el) {
      el.style.maxHeight = 0
    },
    setWidth() {
      this.width = window.innerWidth
    },
    getEventCategoryValue(value) {
      if (value !== this.eventCategory) {
        this.eventCategory = value
        axios.post('select_events.php', {
          eventCategory: value,
          eventYear: this.eventYear,
          event: this.event
        }).then(response => {
          this.eventCategoryOptions = response.data.eventCategoryOptions
          this.eventYearOptions = response.data.eventYearOptions
          this.eventLocationOptions = response.data.eventLocationOptions
        })
      }
    },
    getEventYearValue(value) {
      if (value !== this.eventYear) {
        this.eventYear = value
        axios.post('select_events.php', {
          eventCategory: this.eventCategory,
          eventYear: value,
          event: this.event
        }).then(response => {
          this.eventCategoryOptions = response.data.eventCategoryOptions
          this.eventYearOptions = response.data.eventYearOptions
          this.eventLocationOptions = response.data.eventLocationOptions
        })
      }
    },
    getEventLocationValue(value) {
      if (value !== this.event) {
        axios.post('set_event.php', {
          event: value
        }).then(response => {
          if (response.data.update) {
            this.eventCategory = response.data.eventCategory
            this.eventYear = response.data.eventYear
            this.event = response.data.event
            bus.$emit('eventChange', this.event)
          }
        })
      }
    },
    eventReload: function () {
      axios.post('select_events.php', {
        eventCategory: this.eventCategory,
        eventYear: this.eventYear,
        event: this.event
      }).then(response => {
        this.eventCategoryOptions = response.data.eventCategoryOptions
        this.eventYearOptions = response.data.eventYearOptions
        this.eventLocationOptions = response.data.eventLocationOptions
      })
    }
  },
  mounted() {
    this.setWidth()
    window.addEventListener("resize", this.setWidth)

    if (localStorage.eventCategory) {
      this.eventCategory = localStorage.eventCategory
    }

    if (localStorage.eventYear) {
      this.eventYear = localStorage.eventYear
    }

    if (localStorage.event) {
      this.event = localStorage.event
    }

    if (localStorage.usergroup) {
      this.usergroup = localStorage.usergroup
    }

    axios.post('select_events.php', {
      eventCategory: this.eventCategory,
      eventYear: this.eventYear,
      event: this.event
    }).then(response => {
      this.eventCategoryOptions = response.data.eventCategoryOptions
      this.eventYearOptions = response.data.eventYearOptions
      this.eventLocationOptions = response.data.eventLocationOptions
    })
  },
  created() {
    bus.$on('eventReload', this.eventReload)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth)
    bus.$off('eventReload', this.eventReload)
  },
  watch: {
    toggle(value) {
      this.toggleSideNav(value)
    },
    sidenav(value) {
      this.toggle = value
    },
    width(value) {
      if (value < 1440) {
        this.toggle = false
      } else this.toggle = true
    },
    eventCategory (newEventCategory) {
      localStorage.eventCategory = newEventCategory
    },
    eventYear (newEventYear) {
      localStorage.eventYear = newEventYear
    },
    event (newEvent) {
      localStorage.event = newEvent
    }
  },
  mixins: [waves]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>

.side-nav {
  opacity: 1 !important;
  transition: transform 0.3s linear !important;
  background-color: rgb(227, 228, 229) !important;
  background-image: url('~@/assets/images/sidenav.jpg') !important;
}

.logo-wrapper {
  height: 110px !important;
  padding-bottom: 0px !important;
  padding-top: 25px;
}

.logo-wrapper img {
  padding: 0px !important;
}

.text-bigger a {
  font-size: 17px !important;
  font-weight: 300 !important;
}

.text-bigger i {
  font-size: 17px !important;
  
}

.menu-item i {
  width: 25px;
}

.side-nav .logo-wrapper img {
  width: 200px;
}

</style>
