import Vue from 'vue'
import Router from 'vue-router'
import Products from '@/components/products/Products'
import Finishings from '@/components/finishings/Finishings'
import Incidentals from '@/components/incidentals/Incidentals'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/produkte',
      name: 'Produkte',
      component: Products
    },
    {
      path: '/produkte',
      name: 'Produkte',
      component: Products
    },
    {
      path: '/nebenkosten',
      name: 'Nebenkosten',
      component: Finishings
    },
    {
      path: '/vorkosten',
      name: 'Vorkosten',
      component: Incidentals
    }
  ]
})
